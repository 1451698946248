import { User } from "@/models/user"
import authService from "@/services/auth.service"
import { httpService } from "@/services/http.service"

const state = {
  user: authService.getUser()
}

const actions = {
  login: async ({ commit }, data) => {
    const response = await httpService.post('auth/staff/login', data)
    authService.setToken(response.data.data.token)
    authService.setUser(response.data.data.user)
    commit('SET_USER', response.data.data.user)
  },
  forgotPassword: async (context, data) => {
    await httpService.post('auth/password/forgot', data)
  },
  subscribe: async (context, data) => {
    const response = await httpService.post('subscription', data)
    return response.data.data
  },
  thirdPartySubscribe: async (context, data) => {
    const response = await httpService.post('subscription/third-party', data)
    return response.data.data
  },
  resetPassword: async (context, data) => {
    await httpService.patch('auth/password/reset', data.passwords, { params: { ...data.params } })
  },
  setPassword: async (context, data) => {
    const response = await httpService.patch('auth/password/set', data.passwords, { params: { ...data.params } })
    return response.data.message;
  },
  verifyEmail: async (context, data) => {
    const response = await httpService.patch('auth/verify', {}, { params: { ...data.params } })
    return response.data.message;
  },
  changePassword: async (context, data) => {
    await httpService.patch('auth/password/change', data)
  },
  verifyUser: async ({ commit }) => {
    const response = await httpService.get('auth')
    commit('SET_USER', response.data.data)
  },
  logout: ({ commit }) => {
    authService.removeUser()
    authService.removeToken()
    commit('subscribers/CLEAR', null, { root: true })
    commit('categories/CLEAR', null, { root: true })
    commit('analytics/CLEAR', null, { root: true })
    commit('channels/CLEAR', null, { root: true })
    commit('staff/CLEAR', null, { root: true })
    commit('REMOVE_USER')
  },
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = new User(user)
  },
  REMOVE_USER: (state) => {
    state.user = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}