<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form class="form" @submit.prevent="submitForm()">
      <InputComponent
        :required="true"
        title="First Name"
        v-model="staff.name.first"
      />
      <InputComponent
        :required="true"
        title="Last Name"
        v-model="staff.name.last"
      />
      <InputComponent
        v-if="!data"
        type="email"
        :required="true"
        title="Email Address"
        v-model="staff.email"
      />
      <InputComponent
        :required="true"
        title="Phone Number"
        :minlength="11"
        :maxlength="11"
        v-model="staff.phone.number"
        placeholder="eg 08099882823"
      />
      <SelectComponent
        :required="true"
        title="Role"
        v-model="staff.role"
        :options="roles"
        @click="handleRoleChange($event)"
      />
      <SelectComponent
        v-if="staff.role == 'content-creator' || staff.role == 'content-editor'"
        title="Channels"
        @click="selectChannel($event)"
        :options="channels"
      />
      <div
        class="flex items-center space-x-3 overflow-x-auto"
        v-if="
          selectedChannels &&
          selectedChannels.length > 0 &&
          (staff.role == 'content-creator' || staff.role == 'content-editor')
        "
      >
        <OptionPill
          v-for="channel in selectedChannels"
          :key="channel.id"
          :option="channel"
          @remove="removeChannel(channel)"
        />
      </div>
      <div class="flex items-center justify-end" v-if="data">
        <ButtonComponent type="dark" v-if="false"
          >Deactivate account</ButtonComponent
        >
        <ButtonComponent
          type="error"
          :ordinary="true"
          @click="
            openModal('delete', { type: 'staff', id: staff.id }, 'medium')
          "
        >
          Delete
        </ButtonComponent>
      </div>
      <ButtonComponent :fullWidth="true">{{ text.button }}</ButtonComponent>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'StaffModal',
  }
</script>

<script setup>
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import Staff from "@/models/staff";
import SelectOption from "@/models/select-option";
import { CreateStaffDTO, UpdateStaffDTO } from "@/models/dtos/staff-dto";
import phoneNumberFormatter from "@/providers/phoneNumberFormatter";
import notificationService from "@/services/notification.service";
import { Role } from "@/models/user";

const store = useStore();
const openModal = inject("openModal");

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Staff,
    required: false,
  },
});

const roles = computed(() => {
  return store.state.roles.map((role) => new SelectOption(role));
});

const propData = computed(() => {
  return props.data
    ? new UpdateStaffDTO(props.data)
    : CreateStaffDTO.prototype.createEmpty();
});

const staff = ref(propData.value);

const selectedChannels = ref(
  staff.value.channels.map(
    (channel) => new SelectOption(channel.name, channel.id)
  )
);
const channelData = ref([]);

const submitForm = async () => {
  if (!staff.value.role) {
    return notificationService.warning("Every staff must be assigned a role");
  }

  if (
    (staff.value.role == Role.contentCreator ||
      staff.value.role == Role.contentEditor) &&
    selectedChannels.value.length == 0
  ) {
    return notificationService.error(
      "Content Creators and Editors must be assigned a channel"
    );
  } else if (selectedChannels.value.length >= 1) {
    staff.value.channels = selectedChannels.value.map((channel) => channel.id);
  } else if (
    staff.value.role == Role.administrator ||
    staff.value.role == Role.manager
  ) {
    delete staff.value.channels;
  }

  staff.value.phone.number = phoneNumberFormatter(staff.value.phone.number);
  if (!props.data) {
    const message = await store.dispatch(
      "staff/create",
      new CreateStaffDTO(staff.value)
    );
    notificationService.success(message);
  } else {
    const message = await store.dispatch("staff/update", staff.value);
    notificationService.success(message);
  }
  emit("close");
};

const handleRoleChange = (role) => {
  if (role == Role.administrator || role == Role.manager) {
    selectedChannels.value = [];
  }
};

onMounted(async () => {
  channelData.value = await store.dispatch("channels/getEvery");
});

const channels = computed(() => {
  return channelData.value
    .map((channel) => new SelectOption(channel.name, channel.id))
    .filter(
      ({ id: channel1 }) =>
        !selectedChannels.value.some(
          ({ id: channel2 }) => channel2 === channel1
        )
    );
});

const selectChannel = (channel) => {
  const _channel = channels.value.find((_channel) => _channel.id == channel);
  selectedChannels.value.push(_channel);
};

const removeChannel = (channel) => {
  const channelIndex = selectedChannels.value.findIndex(
    (_channel) => _channel.id == channel.id
  );
  selectedChannels.value.splice(channelIndex, 1);
};

const text = computed(() => {
  return props.data
    ? { title: "Update staff details", button: "Update" }
    : { title: "Create a new staff", button: "Create Staff" };
});

// eslint-disable-next-line
defineExpose({
  text,
  staff,
  roles,
  channels,
  selectedChannels,
  selectChannel,
  removeChannel,
  submitForm,
});
</script>