<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form class="form" @submit.prevent="submitForm()">
      <InputComponent :required="true" title="Name" v-model="category.name" />
      <SelectComponent
        :required="true"
        title="Type"
        v-model="category.type"
        :options="types"
      />
      <SelectComponent
        v-if="category.type == 'time-based'"
        :required="true"
        title="Frequency"
        v-model="category.frequency"
        :options="frequencies"
      />
      <ButtonComponent :fullWidth="true">{{ text.button }}</ButtonComponent>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'CategoryModal',
  }
</script>

<script setup>
import { computed, ref } from "@vue/runtime-core";
import Category, { CategoryType } from "@/models/category";
import SelectOption from "@/models/select-option";
import {
  CreateCategoryDTO,
  UpdateCategoryDTO,
} from "@/models/dtos/category-dto";
import { useStore } from "vuex";
import notificationService from "@/services/notification.service";

const store = useStore();

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Category,
    required: false,
  },
});

const types = computed(() => {
  return store.state.categoryTypes.map((type) => new SelectOption(type));
});

const frequencies = computed(() => {
  return store.state.categoryFrequencies.map(
    (frequency) => new SelectOption(frequency)
  );
});

const propData = computed(() => {
  return props.data
    ? new UpdateCategoryDTO(props.data)
    : new CreateCategoryDTO(props.data);
});

const category = ref(propData.value);

const submitForm = async () => {
  if (category.value.type == CategoryType.selfPaced) {
    delete category.value.frequency;
  }

  if (!props.data.id) {
    const message = await store.dispatch("categories/create", category.value);
    notificationService.success(message);
  } else {
    const message = await store.dispatch("categories/update", category.value);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return props.data.id
    ? { title: "Update category details", button: "Update" }
    : { title: "Create a new category", button: "Create Category" };
});

// eslint-disable-next-line
defineExpose({ text, category, types, frequencies });
</script>