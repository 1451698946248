import { httpService } from "@/services/http.service"
import Book from "@/models/book"
import { Country, LGA, State } from "@/models/address"

const state = {
  countries: [],
  states: [],
  lgas: []
}

const actions = {
  createCountry: async ({ dispatch }, data) => {
    const response = await httpService.post("address/country", data)
    dispatch("getAllCountries")
    return response.data.message;
  },
  getAllCountries: async ({ commit }) => {
    const response = await httpService.get("address/country")
    commit("SET_COUNTRIES", { countries: response.data.data })
  },
  getCountry: async (context, data) => {
    const response = await httpService.get(`address/country/${data}`)
    return new Book(response.data.data)
  },
  updateCountry: async ({ dispatch }, data) => {
    const response = await httpService.patch(`address/country/${data.id}`, data)
    dispatch("getAllCountries")
    return response.data.message
  },
  deleteCountry: async ({ dispatch }, data) => {
    const response = await httpService.delete(`address/country/${data.id}`)
    dispatch("getAllCountries")
    return response.data.message
  },
  createState: async ({ dispatch }, data) => {
    const response = await httpService.post("address/state", data)
    dispatch("getAllStates", { countryId: data.country })
    return response.data.message;
  },
  getAllStates: async ({ commit }, options) => {
    const response = await httpService.get("address/state", { params: options })
    commit("SET_STATES", { states: response.data.data })
  },
  getState: async (context, data) => {
    const response = await httpService.get(`address/state/${data}`)
    return new Book(response.data.data)
  },
  updateState: async ({ dispatch }, data) => {
    const response = await httpService.patch(`address/state/${data.id}`, data)
    dispatch("getAllStates", { countryId: data.country })
    return response.data.message
  },
  deleteState: async ({ dispatch }, data) => {
    const response = await httpService.delete(`address/state/${data.id}`)
    dispatch("getAllStates", { countryId: data.country })
    return response.data.message
  },
  createLGA: async ({ dispatch }, data) => {
    const response = await httpService.post("address/lga", data)
    dispatch("getAllLGAs", { stateId: data.state })
    return response.data.message;
  },
  getAllLGAs: async ({ commit }, options) => {
    const response = await httpService.get("address/lga", { params: options })
    commit("SET_LGAS", { lgas: response.data.data })
  },
  resetLGAs: async ({ commit }) => {
    commit("RESET_LGAS")
  },
  getLGA: async (context, data) => {
    const response = await httpService.get(`address/lga/${data}`)
    return new Book(response.data.data)
  },
  updateLGA: async ({ dispatch }, data) => {
    const response = await httpService.patch(`address/lga/${data.id}`, data)
    dispatch("getAllLGAs", { stateId: data.state })
    return response.data.message
  },
  deleteLGA: async ({ dispatch }, data) => {
    const response = await httpService.delete(`address/lga/${data.id}`)
    dispatch("getAllLGAs", { stateId: data.state })
    return response.data.message
  }
}

const mutations = {
  SET_COUNTRIES: (state, payload) => {
    state.countries = payload.countries.map((_country) => new Country(_country))
  },
  SET_STATES: (state, payload) => {
    state.states = payload.states.map((_state) => new State(_state))
  },
  SET_LGAS: (state, payload) => {
    state.lgas = payload.lgas.map((_lga) => new LGA(_lga))
  },
  RESET_LGAS: (state) => {
    state.lgas = []
  },
  CLEAR: (state) => {
    state.countries = []
    state.states = []
    state.lgas = []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}