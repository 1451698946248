<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form class="grid md:grid-cols-3 gap-6" @submit.prevent="submitForm()">
      <div class="md:col-span-1 rounded-lg overflow-hidden relative">
        <div class="
            cursor-pointer
            absolute
            z-20
            flex flex-col
            items-center
            justify-center
            w-full
            h-full
            bg-gray-300 bg-opacity-30
          " @click="openImageUploader()">
          <IconComponent icon="charm:upload" />
          <p class="small bold select-none">Upload thumbnail</p>
        </div>
        <input @change="uploadImage($event)" type="file" class="hidden" name="channel-image" id="channel-image"
          accept="image/*" />
        <img class="w-full h-full object-cover object-center" v-if="displayImage" :src="displayImage" />
      </div>
      <div class="form md:col-span-2">
        <InputComponent :required="true" title="Name" v-model="channel.name" />
        <InputComponent type="textarea" :required="true" title="Description" v-model="channel.description" />
        <div class="flex items-center justify-between space-x-4">
          <ButtonComponent v-if="data" :ordinary="true" type="error" @click="deleteChannel()">
            Delete
          </ButtonComponent>
          <ButtonComponent :fullWidth="true">{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ChannelModal',
}
</script>

<script setup>
import { useStore } from "vuex";
import { computed, inject, ref } from "@vue/runtime-core";
import { CreateChannelDTO, UpdateChannelDTO } from "@/models/dtos/channel-dto";
import Channel from "@/models/channel";
import notificationService from "@/services/notification.service";
import { useRouter } from "vue-router";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Channel,
    required: false,
  },
});

const store = useStore();
const router = useRouter();
const openModal = inject("openModal");
// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const propData = computed(() => {
  return props.data
    ? new UpdateChannelDTO(props.data)
    : CreateChannelDTO.prototype.createEmpty();
});

const channel = ref(propData.value);
const displayImage = ref(channel.value.image);
const uploadedImage = ref();

const submitForm = async () => {
  const formData = new FormData();
  uploadedImage.value ? formData.append("image", uploadedImage.value) : null;
  delete channel.value.image;

  for (const data in channel.value) {
    formData.append(data, channel.value[data]);
  }

  if (!props.data) {
    const message = await store.dispatch("channels/create", formData);
    notificationService.success(message);
  } else {
    const message = await store.dispatch("channels/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return props.data
    ? { title: "Update channel details", button: "Update" }
    : { title: "Create a new channel", button: "Create Channel" };
});

const openImageUploader = () => {
  const image = document.getElementById("channel-image");
  image.click();
};
const uploadImage = ($event) => {
  const image = $event.target.files[0];
  displayImage.value = URL.createObjectURL(image);
  uploadedImage.value = image;
};

const goToChannels = () => {
  router.push({ name: "channels" });
};

const deleteChannel = () => {
  emit("close");
  openModal(
    "delete",
    { type: "channel", id: channel.value.id },
    "medium",
    goToChannels
  );
};

// eslint-disable-next-line
defineExpose({
  text,
  channel,
  displayImage,
  openImageUploader,
  uploadImage,
  submitForm,
});
</script>
