<template>
  <div class="relative" v-click-outside="close" v-if="user">
    <div class="flex items-center space-x-2 cursor-pointer" @click="toggle()">
      <p class="xs">{{ formatAndCapitalize(user.role) }}</p>
      <img
        :src="user.image"
        alt="Logo"
        class="w-10 h-10 object-cover object-center rounded-full"
      />
      <IconComponent v-if="!opened" icon="akar-icons:chevron-down" />
      <IconComponent v-else icon="akar-icons:chevron-up" />
    </div>
    <div class="dropdown" v-if="opened">
      <div class="flex flex-col w-full rounded">
        <div class="item">
          <p class="text-gray-400 truncate">{{ user.fullName }}</p>
        </div>
        <div @click="goToProfile()" class="item">
          <p>Profile</p>
        </div>
        <div @click="logout()" class="item">
          <p class="text-system-red">Logout</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProfileThumb',
  }
</script>

<script setup>
import { User } from "@/models/user";
import { inject, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

// eslint-disable-next-line
const props = defineProps({
  user: {
    type: User,
  },
});
const store = useStore();
const router = useRouter();

const opened = ref(false);
const formatAndCapitalize = inject("formatAndCapitalize")

const toggle = () => {
  opened.value = !opened.value;
};

const close = () => {
  opened.value = false;
};

const goToProfile = () => {
  close();
  router.push({ name: "settings" });
};

const logout = async () => {
  close();
  await router.push({ name: "login" });
  store.dispatch("auth/logout");
};

// eslint-disable-next-line
defineExpose({ opened, toggle, close, goToProfile, logout });
</script>

<style lang="scss" scoped>
.dropdown {
  @apply absolute shadow bg-white top-[130%] z-40 right-0 rounded max-h-[200px] w-[160px] overflow-y-auto;

  .item {
    @apply flex w-full items-center p-2 px-4 border-transparent border-l-2 relative;
    @apply cursor-pointer w-full border-gray-100 hover:bg-gray-200 select-none;

    p {
      @apply text-sm;
    }
  }
}
</style>
