import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Order from "@/models/order"

const state = {
  list: Pagination.prototype.createEmpty(),
}

const actions = {
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("order", { params: options })
    commit("SET_ORDERS", { orders: response.data.data, options })
  },
  get: async (context, data) => {
    const response = await httpService.get(`order/${data}`)
    return new Order(response.data.data)
  },
  update: async (context, data) => {
    const response = await httpService.patch(`order/${data.id}`, { status: data.status })
    return response.data.message
  }
}

const mutations = {
  SET_ORDERS: (state, payload) => {
    const orderPagination = new Pagination(payload.orders, "orders/getAll", payload.options)
    orderPagination.result = orderPagination.result.map((_order) => new Order(_order))
    state.list = orderPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}