export class CreateReleaseDTO {
  constructor(release) {
    this.title = release.title;
    this.image = release.image;
    this.magazine = release.magazine;
    this.content = release.content;
    this.document = release.document;
    this.publishDate = release.publishDate;
  }

  createEmpty() {
    return new CreateReleaseDTO({ title: '', content: '' });
  }
}

export class UpdateReleaseDTO extends CreateReleaseDTO {
  constructor(release) {
    super(release)
    this.id = release.id
    this.isPublished = release.isPublished
  }
}