<template>
  <div>
    <h4>{{ text.title }}</h4>
    <p class="small">{{ text.message }}</p>
    <div class="flex items-center justify-end space-x-4">
      <ButtonComponent type="dark" @click="negativeAction()">
        No
      </ButtonComponent>
      <ButtonComponent type="error" @click="affirmativeAction()">
        Yes
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteModal",
};
</script>

<script setup>
import notificationService from "@/services/notification.service";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const store = useStore();

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const text = computed(() => {
  const title = `Are you sure you want to delete this ${props.data.type}?`;
  switch (props.data.type) {
    case "category":
      return {
        title,
        message:
          "This is an irreversible action and will delete all lessons associated with it. Do you wish to proceed?",
      };
    case "staff":
      return {
        title,
        message: "This is an irreversible action, do you wish to proceed?",
      };
    case "channel":
      return {
        title,
        message:
          "This is an irreversible action and will delete all categories and lessons associated with it. Do you wish to proceed?",
      };
    case "magazine":
      return {
        title,
        message:
          "This is an irreversible action and will delete all releases associated with it. Do you wish to proceed?",
      };
    case "book":
      return {
        title,
        message:
          "This is an irreversible action. Do you wish to proceed?",
      };
    case "release":
      return {
        title,
        message: "This is an irreversible action. Do you wish to proceed?",
      };
    case "lesson":
      return {
        title,
        message: "This is an irreversible action. Do you wish to proceed?",
      };
    case "account":
      return {
        title: "You are about to deactivate this account.",
        message:
          "You will need to contact an administrator to reactivate it. Do you wish to proceed?",
      };
  }
});

const negativeAction = () => {
  emit("close");
};

const affirmativeAction = async () => {
  let message = "";
  switch (props.data.type) {
    case "category":
      message = await store.dispatch("categories/delete", props.data.id);
      break;
    case "staff":
      message = await store.dispatch("staff/delete", props.data.id);
      break;
    case "channel":
      message = await store.dispatch("channels/delete", props.data.id);
      break;
    case "magazine":
      message = await store.dispatch("magazines/delete", props.data.id);
      break;
    case "book":
      message = await store.dispatch("books/delete", props.data.id);
      break;
    case "lesson":
      message = await store.dispatch("lessons/delete", props.data.id);
      break;
    case "release":
      message = await store.dispatch("releases/delete", props.data.id);
      break;
    case "account":
      break;
  }
  await emit("closeWithFunction");
  notificationService.success(message);
};

// eslint-disable-next-line
defineExpose({ text, negativeAction, affirmativeAction });
</script>