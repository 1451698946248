export class Pagination {
  constructor(pagination, getAllRoute, options) {
    this.result = pagination.result || [];
    this.totalCount = pagination.totalCount || null;
    this.options = { ...options, page: new Page(pagination.page) };

    this.limit = 10;
    this.page = new Page(pagination.page);

    this.getAllRoute = getAllRoute || '';
  }

  createEmpty() {
    return new Pagination({ result: [] });
  }
}

class Page {
  constructor(page) {
    if (page) {
      this.total = page.total
      this.current = page.current
      this.hasPrevious = page.hasPrevious
      this.hasNext = page.hasNext
    }
  }
}