<template>
  <div class="">
    <div class="space-y-3">
      <div class="flex items-center justify-between">
        <div>
          <p class="xs bold">Order ID</p>
          <h5>#{{ order.orderID }}</h5>
        </div>
        <StatusUpdater v-if="order.isPaid == 'paid'" :order="order" />
      </div>
      <div class="space-y-4">
        <div class="flex items-end justify-between">
          <p>Order Details</p>
          <p class="xs">{{ order.cart.size }}</p>
        </div>
        <div class="h-full max-h-52 space-y-4 overflow-y-auto">
          <div
            class="flex items-center space-x-2 justify-between w-full"
            v-for="item in order.cart.items"
            :key="item.id"
          >
            <div class="flex items-end space-x-1">
              <p class="xs">{{ item.quantity }}x</p>
              <div class="flex space-x-2">
                <img
                  class="object-contain h-14"
                  :src="item.book.image"
                  :alt="item.book.name"
                />
                <p class="small">{{ item.book.name }}</p>
              </div>
            </div>
            <p>{{ item.formattedPrice }}</p>
          </div>
        </div>
        <div class="flex items-end justify-between">
          <p class="text-sm">Cart Total</p>
          <p class="font-semibold text-gray-700">
            {{ order.cart.formattedTotalPrice }}
          </p>
        </div>
        <div class="flex items-end justify-between">
          <p class="text-sm">Delivery Fee</p>
          <p class="font-semibold text-gray-700">
            {{ order.shippingAddress.deliveryFee }}
          </p>
        </div>
        <div class="flex justify-end">
          <h4>{{ order.formattedPrice }}</h4>
        </div>
      </div>
      <div class="space-y-1">
        <p class="bold small">Shipping Details</p>
        <!-- <div class="grid grid-cols-2 gap-2">
          <div>
            <p class="xs">Street Address</p>
            <p class="">{{ order.shippingAddress.streetAddress }}</p>
          </div>
          <div>
            <p class="xs">City</p>
            <p class="">{{ order.shippingAddress.lga.name }}</p>
          </div>
          <div>
            <p class="xs">Lagos</p>
            <p class="">{{ order.shippingAddress.state.name }}</p>
          </div>
          <div>
            <p class="xs">Country</p>
            <p class="">{{ order.shippingAddress.country.name }}</p>
          </div>
        </div> -->
        <p>{{ order.shippingAddress.fullAddress }}</p>
      </div>
      <div class="space-y-1">
        <p class="bold small">Customer Details</p>
        <div class="flex space-x-3">
          <img
            class="object-cover h-20 w-20 rounded"
            :src="order.subscriber.image"
          />
          <div class="flex flex-col space-y-1">
            <p>{{ order.subscriber.fullName }}</p>
            <a
              class="text-sm text-primary-500"
              :href="`mailto:${order.subscriber.email}`"
              >{{ order.subscriber.email }}</a
            >
            <a
              class="text-sm text-primary-500"
              :href="`tel:${order.subscriber.phone.number}`"
              >{{ order.subscriber.phone.number }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderModal",
};
</script>

<script setup>
import { computed } from "@vue/runtime-core";
import Order from "@/models/order";
import StatusUpdater from "../StatusUpdater.vue";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Order,
    required: false,
  },
});

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const order = computed(() => props.data);

// eslint-disable-next-line
defineExpose({
  order,
});
</script>
