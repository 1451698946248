export class CreateStaffDTO {
  constructor(staff) {
    this.name = staff.name;
    this.email = staff.email;
    this.phone = staff.phone;
    this.role = staff.role;
    this.channels = staff.channels || [];
  }

  createEmpty() {
    return new CreateStaffDTO({ name: { first: "", last: "" }, phone: { number: "" } });
  }
}

export class UpdateStaffDTO extends CreateStaffDTO {
  constructor(staff) {
    super(staff)
    this.id = staff.id
    this.status = staff.status
  }
}