export default class Channel {
  constructor(channel) {
    this.id = channel._id
    this.name = channel.name;
    this.image = channel.image;
    this.description = channel.description;
    this.subscribersCount = channel.subscribersCount;
    this.createdAt = channel.createdAt;
    this.updatedAt = channel.updatedAt;
  }

  get subscribers() {
    return `${this.subscribersCount.toLocaleString()} subscriber${this.subscribersCount !== 1 ? 's' : ''}`
  }
}