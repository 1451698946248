<template>
  <div class="relative" v-click-outside="closeOptions">
    <div
      @click="toggleOptions()"
      class="rounded-[28px] h-max text-center w-max px-[18px] py-2 flex items-center cursor-pointer"
      :class="{
        'bg-system-green bg-opacity-[0.41] text-system-green':
          status == 'active' ||
          status == 'published' ||
          status == 'delivered' ||
          status == 'shipped' ||
          status == 'paid',
        'bg-gray-300 text-gray-900':
          status == 'inactive' ||
          status == 'unpublished' ||
          status == 'pending',
        'bg-system-red bg-opacity-[0.41] text-system-red':
          status == 'deactivated' || status == 'cancelled',
      }"
    >
      <p class="xs bold">
        {{ status.charAt(0).toUpperCase() + status.slice(1) }}
      </p>
      <IconComponent
        :icon="
          optionsOpened
            ? 'material-symbols:arrow-drop-up-rounded'
            : 'material-symbols:arrow-drop-down-rounded'
        "
      />
    </div>
    <div
      v-if="optionsOpened"
      class="absolute top-full right-0 z-10 mt-2 rounded-xl w-full text-right bg-gray-300"
    >
      <div
        @click="updateOrderStatus(status)"
        class="pt-3 pb-1.5 last:pb-3 px-[18px] cursor-pointer"
        v-for="status in orderStatuses"
        :key="status"
      >
        <p class="xs capitalize hover:font-semibold">
          {{ status }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusUpdater",
};
</script>

<script setup>
import { ref } from "vue";
import Order, { OrderStatuses } from "@/models/order";
import { useStore } from "vuex";
import { UpdateOrderDTO } from "@/models/dtos/order-dto";
import notificationService from "@/services/notification.service";
// eslint-disable-next-line
const props = defineProps({
  order: {
    type: Order,
    required: true,
  },
});
const store = useStore();

const optionsOpened = ref(false);
const status = ref(props.order.status);

const orderStatuses = Object.values(OrderStatuses);

const updateOrderStatus = async (_status) => {
  try {
    const message = await store.dispatch(
      "orders/update",
      new UpdateOrderDTO({ id: props.order.id, status: _status })
    );
    notificationService.success(message);
    optionsOpened.value = false;
    status.value = _status;
    await store.dispatch("orders/getAll");
  } catch (error) {
    notificationService.error(error.response.data.error.message);
    optionsOpened.value = false;
  }
};

const toggleOptions = () => {
  optionsOpened.value = !optionsOpened.value;
};

const closeOptions = () => {
  optionsOpened.value = false;
};

// eslint-disable-next-line
defineExpose({
  status,
  optionsOpened,
  orderStatuses,
  toggleOptions,
  closeOptions,
  updateOrderStatus,
});
</script>