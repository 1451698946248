<template>
  <div class="relative w-full" v-click-outside="close">
    <div class="w-full" @click="toggle()">
      <div
        class="custom-input rounded-[10px] cursor-pointer space-x-2"
        :class="{
          'disabled-input': disabled,
        }"
      >
        <IconComponent icon="fluent:filter-12-regular" />
        <p class="small whitespace-nowrap truncate">
          <slot />
        </p>
      </div>
    </div>
    <div
      class="
        absolute
        shadow
        bg-white
        top-100
        z-40
        right-1
        rounded
        max-h-select
        overflow-y-auto
      "
      v-if="opened"
    >
      <div class="flex flex-col w-full rounded-t rounded-b">
        <div class="w-full" v-if="options.length < 1">
          <div
            class="
              flex
              w-full
              items-center
              p-2
              pl-2
              border-transparent border-l-2
              relative
            "
          >
            <div class="w-full items-center flex">
              <div class="mx-2 select-none">No options available</div>
            </div>
          </div>
        </div>
        <div
          class="cursor-pointer w-full border-gray-100 hover:bg-gray-200"
          @click="selectOption(option)"
          v-for="option in options"
          :key="option.id"
        >
          <div
            class="
              flex
              w-full
              items-center
              p-2
              pl-2
              border-transparent border-l-2
              relative
            "
          >
            <div class="w-full items-center flex">
              <div class="mx-2 select-none">{{ option.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FilterComponent',
  }
</script>

<script setup>
import { ref } from "@vue/runtime-core";

// eslint-disable-next-line
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const opened = ref(false);

const toggle = () => {
  opened.value = !opened.value;
};

const close = () => {
  opened.value = false;
};

// eslint-disable-next-line
const emit = defineEmits(["click"]);

const selectOption = (option) => {
  emit("click", option);
  toggle()
};

// eslint-disable-next-line
defineExpose({ opened, toggle, close, selectOption });
</script>

<style scoped>
.top-100 {
  top: 130%;
}
.bottom-100 {
  bottom: 100%;
}
.max-h-select {
  max-height: 200px;
}
</style>
