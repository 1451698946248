<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form @submit.prevent="submitForm()">
      <div class="grid md:grid-cols-3 gap-6 mb-6">
        <div class="md:col-span-1 rounded-lg overflow-hidden relative">
          <div class="
              cursor-pointer
              absolute
              z-20
              flex flex-col
              items-center
              justify-center
              w-full
              h-full
              bg-gray-300 bg-opacity-30
            " @click="openImageUploader()">
            <IconComponent icon="charm:upload" />
            <p class="small bold select-none">Upload thumbnail</p>
          </div>
          <input @change="uploadImage($event)" type="file" class="hidden" name="lesson-image" id="lesson-image" />
          <img class="w-full h-full object-cover object-center" v-if="displayImage" :src="displayImage" />
        </div>
        <div class="form md:col-span-2">
          <InputComponent :required="true" title="Title" v-model="lesson.title" />
          <SelectComponent :required="true" title="Category" v-model="selectedCategory" :options="categories" />
          <InputComponent v-if="selectedCategoryType != 'self-paced'" type="date" :required="true" title="Publish Date"
            v-model="publishDate" />
        </div>
      </div>
      <QuillEditor v-model:content="lesson.content" contentType="html" class="h-40 md:!h-60" theme="snow"
        toolbar="full" />
      <div class="flex items-center justify-between mt-6">
        <div>
          <ButtonComponent v-if="data && hasAdminAccess" :ordinary="true" type="error" @click="deleteLesson()">
            Delete
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4 ml-auto" v-if="!data">
          <ButtonComponent>Save Lesson</ButtonComponent>
          <ButtonComponent @click="submitForm(true)" :ordinary="true" type="success" v-if="hasPublishAccess">
            {{ text.button }}
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4" v-else>
          <ButtonComponent @click="togglePublish()" :ordinary="true" type="success"
            v-if="hasPublishAccess && !lesson.isPublished">
            Publish
          </ButtonComponent>
          <ButtonComponent @click="togglePublish()" :ordinary="true" type="dark"
            v-if="hasPublishAccess && lesson.isPublished">
            Unpublish
          </ButtonComponent>
          <ButtonComponent>{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "LessonModal",
};
</script>

<script setup>
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import Lesson from "@/models/lesson";
import SelectOption from "@/models/select-option";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { CreateLessonDTO, UpdateLessonDTO } from "@/models/dtos/lesson-dto";
import { CategoryPaginationOptionsDTO } from "@/models/dtos/pagination-options-dto";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import notificationService from "@/services/notification.service";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Lesson,
    required: false,
  },
});

const store = useStore();
const route = useRoute();
const openModal = inject("openModal");

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);
const today = new Date().toLocaleDateString("sv-SE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const propData = computed(() => {
  return props.data
    ? new UpdateLessonDTO(props.data)
    : CreateLessonDTO.prototype.createEmpty();
});
const user = computed(() => {
  return store.state.auth.user;
});

const lesson = ref(propData.value);
const selectedCategory = ref(lesson.value.category?.id);
const publishDate = ref(lesson.value.publishDate || today);

const displayImage = ref(lesson.value.image);
const uploadedImage = ref();
const categoriesData = ref([]);

onMounted(async () => {
  categoriesData.value = await store.dispatch(
    "categories/getAllForChannel",
    new CategoryPaginationOptionsDTO({ channel: route.params.id })
  );
});

const publishLesson = async (lessonID) => {
  const formData = new FormData();
  formData.append("id", lessonID);
  formData.append("isPublished", true);
  await store.dispatch("lessons/update", formData);
};

const hasAdminAccess = computed(() => {
  return store.state.adminAccessRoles.includes(store.state.auth.user?.role);
});

const hasPublishAccess = computed(() => {
  return store.state.publishAccessRoles.includes(store.state.auth.user?.role);
});

const submitForm = async (publish) => {
  if (!lesson.value.content) {
    return notificationService.warning(
      "Please provide content for the lesson."
    );
  }

  const formData = new FormData();
  uploadedImage.value ? formData.append("image", uploadedImage.value) : null;
  lesson.value.category = selectedCategory.value;
  lesson.value.publishDate = `${publishDate.value}T00:00:00`;
  delete lesson.value.image;

  for (const data in lesson.value) {
    formData.append(data, lesson.value[data]);
  }

  if (!props.data) {
    const response = await store.dispatch("lessons/create", formData);
    if (publish) {
      await publishLesson(response.data._id);
    }
    notificationService.success(response.message);
  } else {
    const message = await store.dispatch("lessons/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const categories = computed(() => {
  return categoriesData.value.map(
    (category) => new SelectOption(category.name, category.id)
  );
});

const selectedCategoryType = computed(() => {
  return categoriesData.value.find(
    (category) => category.id == selectedCategory.value
  )?.type;
});

const text = computed(() => {
  return props.data
    ? { title: "Update lesson details", button: "Update" }
    : { title: "Create a new Lesson", button: "Publish Lesson" };
});

const togglePublish = async () => {
  const formData = new FormData();
  formData.append("id", lesson.value.id);
  formData.append("isPublished", !lesson.value.isPublished);
  const message = await store.dispatch("lessons/update", formData);
  notificationService.success(message);
  await emit("closeWithFunction");
};

const openImageUploader = () => {
  const image = document.getElementById("lesson-image");
  image.click();
};

const uploadImage = ($event) => {
  const image = $event.target.files[0];
  displayImage.value = URL.createObjectURL(image);
  uploadedImage.value = image;
};

const deleteLesson = () => {
  const closeFunction = store.state.modal.closeFunction;
  openModal(
    "delete",
    { type: "lesson", id: lesson.value.id },
    "medium",
    closeFunction
  );
};

// eslint-disable-next-line
defineExpose({
  text,
  user,
  today,
  lesson,
  hasAdminAccess,
  hasPublishAccess,
  categories,
  publishDate,
  displayImage,
  selectedCategory,
  selectedCategoryType,
  openImageUploader,
  uploadImage,
});
</script>
