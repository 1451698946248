import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Subscriber from "@/models/subscriber"

const state = {
  list: Pagination.prototype.createEmpty(),
}

const actions = {
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("subscriber", { params: options })
    commit("SET_SUBSCRIBERS", response.data.data)
  },
}

const mutations = {
  SET_SUBSCRIBERS: (state, subscriber) => {
    const subscriberPagination = new Pagination(subscriber, "subscribers/getAll")
    subscriberPagination.result = subscriberPagination.result.map((_subscriber) => new Subscriber(_subscriber))
    state.list = subscriberPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}