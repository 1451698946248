import { User } from "./user";

export class StaffActivity {
  constructor(activity) {
    this.id = activity._id;
    this.status = activity.status;
    this.type = activity.type;
    this.message = activity.message;
    this.info = activity.info;
    this.user = new User(activity.user);
    this.createdAt = activity.createdAt;
    this.updatedAt = activity.updatedAt;
  }
}