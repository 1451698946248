import { defineComponent, shallowRef} from "vue";
import BaseLayout from "./layouts/baseLayout.vue";

import '@/assets/styles/main.scss';

export default defineComponent({
  name: 'App',
  data: () => ({
    layout: shallowRef(BaseLayout)
  }),
})