import Category from "./category";

export default class Lesson {
  constructor(lesson) {
    this.id = lesson._id
    this.title = lesson.title;
    this.image = lesson.image;
    this.content = lesson.content;
    this.contentExcerpt = lesson.content.slice(0, 150);
    this.category = new Category(lesson.category);
    this.isPublished = lesson.isPublished;
    this.publishDate = new Date(lesson.publishDate).toISOString().split('T')[0];
    this.createdAt = lesson.createdAt;
    this.updatedAt = lesson.updatedAt;
  }
}