import currencyRenderer from "@/providers/currencyRenderer";

export class Address {
  constructor(address) {
    this.id = address._id
    this.lga = address.lga ? new LGA(address.lga) : null;
    this.state = address.state ? new State(address.state) : null;
    this.country = new Country(address.country);
    this.streetAddress = address.streetAddress;
    this.createdAt = address.createdAt;
    this.updatedAt = address.updatedAt;
  }

  get fullAddress() {
    return `${this.streetAddress}, ${this.lga?.name ?? ""}, ${this.state?.name ?? ""}, ${this.country?.name ?? ""}`
  }

  get deliveryFee() {
    return currencyRenderer(this.lga?.deliveryFee || 0);
  }
}

export class Country {
  constructor(country) {
    this.id = country._id;
    this.name = country.name;
    this.sortName = country.sortName;
    this.code = country.code;
    this.createdAt = country.createdAt;
    this.updatedAt = country.updatedAt;
  }
}

export class State {
  constructor(state) {
    this.id = state._id;
    this.name = state.name;
    this.country = state.country;
    this.createdAt = state.createdAt;
    this.updatedAt = state.updatedAt;
  }
}

export class LGA {
  constructor(lga) {
    this.id = lga._id;
    this.name = lga.name;
    this.state = lga.state;
    this.deliveryFee = lga.deliveryFee;
    this.createdAt = lga.createdAt;
    this.updatedAt = lga.updatedAt;
  }
}