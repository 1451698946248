import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Channel from "@/models/channel"

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("channel", data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("channel", { params: options })
    commit("SET_CHANNELS", response.data.data)
  },
  getEvery: async () => {
    const response = await httpService.get("channel", { params: { limit: 100 } })
    return response.data.data.result.map((channel) => new Channel(channel))
  },
  get: async (context, data) => {
    const response = await httpService.get(`channel/${data}`)
    return new Channel(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`channel/${data.get('id')}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`channel/${data}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_CHANNELS: (state, channels) => {
    const channelsPagination = new Pagination(channels, "channels/getAll")
    channelsPagination.result = channelsPagination.result.map((_channel) => new Channel(_channel))
    state.list = channelsPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}