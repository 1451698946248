export class PaginationOptionsDTO {
  constructor(options) {
    if (options) {
      this.limit = options.limit || 10;
      this.page = options.page || 1;
      this.search = options.search || "";
    }
  }
}

export class StaffPaginationOptionsDTO extends PaginationOptionsDTO {
  constructor(options) {
    super(options)
    if (options && options.role) {
      this.role = options.role;
    }
  }
}

export class CategoryPaginationOptionsDTO extends PaginationOptionsDTO {
  constructor(options) {
    super(options)
    this.limit = 100;
    if (options) {
      this.channel = options.channel;
    }
  }
}

export class LessonPaginationOptionsDTO extends PaginationOptionsDTO {
  constructor(options) {
    super(options)
    this.limit = 20;
    this.channel = options.channel;
    if (options && options.category) {
      this.category = options.category;
      this.isPublished = options.isPublished;
    }
  }
}

export class ReleasePaginationOptionsDTO extends PaginationOptionsDTO {
  constructor(options) {
    super(options)
    this.limit = 10;
    this.magazine = options.magazine;
    if (options && options.category) {
      this.category = options.category;
      this.isPublished = options.isPublished;
    }
  }
}

export class OrderPaginationOptionsDTO extends PaginationOptionsDTO {
  constructor(options) {
    super(options)
    this.limit = 10;
    if (options && options.status) {
      this.status = options.status;
    }
  }
}