export class CreateMagazineDTO {
  constructor(magazine) {
    this.name = magazine.name;
    this.description = magazine.description;
  }

  createEmpty() {
    return new CreateMagazineDTO({ name: '', description: '' });
  }
}

export class UpdateMagazineDTO extends CreateMagazineDTO {
  constructor(magazine) {
    super(magazine)
    this.id = magazine.id
    this.image = magazine.image
  }
}