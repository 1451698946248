import currencyRenderer from "@/providers/currencyRenderer";

export default class Book {
  constructor(book) {
    this.id = book._id
    this.name = book.name;
    this.image = book.image;
    this.price = book.price;
    this.author = book.author;
    this.summary = book.summary;
    this.quantity = book.quantity;
    this.quantitySold = book.quantitySold;
    this.createdAt = book.createdAt;
    this.updatedAt = book.updatedAt;
  }

  init() {
    return new Book({})
  }

  get formattedPrice() {
    if (Number(this.price) === 0) return 'FREE';
    return currencyRenderer(this.price);
  }
}