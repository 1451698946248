export default class Release {
  constructor(release) {
    this.id = release._id
    this.title = release.title;
    this.image = release.image;
    this.content = release.content;
    this.document = release.document;
    this.contentExcerpt = release.content ? release.content.slice(0, 150) : "";
    this.isPublished = release.isPublished;
    this.publishDate = new Date(release.publishDate).toISOString().split('T')[0];
    this.createdAt = release.createdAt;
    this.updatedAt = release.updatedAt;
  }

  get status() {
    return this.isPublished ? 'published' : 'unpublished'
  }

  get formattedPublishDate() {
    return new Date(this.publishDate).toLocaleDateString('en-GB', { year: "numeric", month: "long", day: "2-digit" })
  }
}