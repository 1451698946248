export class CreateCountryDTO {
  constructor(country) {
    this.name = country.name;
    this.sortName = country.sortName;
    this.code = country.code;
  }

  init() {
    return new CreateCountryDTO({ name: '' });
  }
}

export class UpdateCountryDTO extends CreateCountryDTO {
  constructor(country) {
    super(country)
    this.id = country.id
  }
}

export class CreateStateDTO {
  constructor(state) {
    this.name = state.name;
    this.country = state.country;
  }

  init() {
    return new CreateStateDTO({ name: '', country: '' });
  }
}

export class UpdateStateDTO extends CreateStateDTO {
  constructor(state) {
    super(state)
    this.id = state.id
  }
}

export class CreateLGADTO {
  constructor(lga) {
    this.name = lga.name;
    this.state = lga.state;
    this.deliveryFee = lga.deliveryFee;
  }

  init() {
    return new CreateLGADTO({ name: '', state: '', deliveryFee: 0 });
  }
}

export class UpdateLGADTO extends CreateLGADTO {
  constructor(lga) {
    super(lga)
    this.id = lga.id
  }
}