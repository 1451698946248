export class CreateBookDTO {
  constructor(book) {
    this.name = book.name;
    this.image = book.image;
    this.summary = book.summary;
    this.price = book.price || 0;
    this.quantity = book.quantity || 1;
    this.author = book.author;
  }

  createEmpty() {
    return new CreateBookDTO({ name: '', summary: '' });
  }
}

export class UpdateBookDTO extends CreateBookDTO {
  constructor(book) {
    super(book)
    this.id = book.id
  }
}