const phoneNumberFormatter = (phoneNumber) => {
  if (phoneNumber.startsWith("0") && phoneNumber.length == 11) {
    return phoneNumber.slice(1, 11)
  } else if (!phoneNumber.startsWith("0") && phoneNumber.length == 10) {
    return phoneNumber
  } else {
    return phoneNumber
  }
}

export default phoneNumberFormatter;