<template>
  <div class="">
    <h4>{{ !propData ? "Add New Shipping Zone" : `Edit ${propData.type}` }}</h4>
    <div v-if="!propData" class="flex items-center space-x-3">
      <div
        v-for="addressType in addressTypes"
        :key="addressType"
        @click="selectAddressType(addressType)"
        :class="`${
          selectedAddressType == addressType ? 'bg-primary-500' : 'bg-gray-400'
        } rounded py-2 px-6 text-white cursor-pointer`"
      >
        {{ addressType }}
      </div>
    </div>
    <form
      class="space-y-8"
      v-if="selectedAddressType == 'Country'"
      @submit.prevent="submitCountry()"
    >
      <div class="space-y-4">
        <InputComponent
          :required="true"
          title="Country Name"
          v-model="countryPayload.name"
        />
        <div class="flex items-center justify-between space-x-4">
          <InputComponent
            :required="true"
            title="Country Sort Name"
            placeholder="eg. NG"
            v-model="countryPayload.sortName"
          />
          <InputComponent
            :required="true"
            title="Country Code"
            placeholder="eg. 234"
            v-model="countryPayload.code"
          />
        </div>
      </div>
      <div class="flex items-center justify-between space-x-4">
        <ButtonComponent
          :ordinary="true"
          @click="deleteCountry()"
          type="error"
          v-if="false && propData"
          >Delete Country
        </ButtonComponent>
        <ButtonComponent :fullWidth="true"
          >{{ !propData ? "Add" : "Edit" }} Country
        </ButtonComponent>
      </div>
    </form>
    <form
      class="space-y-8"
      v-if="selectedAddressType == 'State'"
      @submit.prevent="submitState()"
    >
      <div class="space-y-4">
        <SelectComponent
          :required="true"
          title="Country"
          v-model="statePayload.country"
          :options="countries"
        />
        <InputComponent
          :required="true"
          title="State Name"
          v-model="statePayload.name"
        />
      </div>
      <div class="flex items-center justify-between space-x-4">
        <ButtonComponent
          :ordinary="true"
          @click="deleteState()"
          type="error"
          v-if="false && propData"
          >Delete State
        </ButtonComponent>
        <ButtonComponent :fullWidth="true"
          >{{ !propData ? "Add" : "Edit" }} State
        </ButtonComponent>
      </div>
    </form>
    <form
      class="space-y-8"
      v-if="selectedAddressType == 'LGA'"
      @submit.prevent="submitLGA()"
    >
      <div class="space-y-4">
        <SelectComponent
          :required="true"
          title="State"
          v-model="lgaPayload.state"
          :options="states"
        />
        <InputComponent
          :required="true"
          title="LGA Name"
          v-model="lgaPayload.name"
        />
        <InputComponent
          :required="true"
          type="number"
          title="Delivery Fee (₦)"
          v-model="lgaPayload.deliveryFee"
        />
      </div>
      <div class="flex items-center justify-between space-x-4">
        <ButtonComponent
          :ordinary="true"
          @click="deleteLGA()"
          type="error"
          v-if="false && propData"
          >Delete LGA
        </ButtonComponent>
        <ButtonComponent :fullWidth="true"
          >{{ !propData ? "Add" : "Edit" }} LGA
        </ButtonComponent>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AddressModal",
};
</script>

<script setup>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import SelectOption from "@/models/select-option";
import {
  CreateCountryDTO,
  CreateLGADTO,
  CreateStateDTO,
  UpdateCountryDTO,
  UpdateLGADTO,
  UpdateStateDTO,
} from "@/models/dtos/address-dto";
import notificationService from "@/services/notification.service";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const addressTypes = ["Country", "State", "LGA"];

const store = useStore();

const lgaPayload = ref(CreateLGADTO.prototype.init());
const statePayload = ref(CreateStateDTO.prototype.init());
const countryPayload = ref(CreateCountryDTO.prototype.init());
const selectedAddressType = ref(addressTypes[0]);

const propData = computed(() => {
  if (!props.data) return null;
  const type = props.data.type;
  const data = props.data._payload;
  switch (type) {
    case "Country":
      countryPayload.value = new UpdateCountryDTO(data);
      break;
    case "State":
      statePayload.value = new UpdateStateDTO(data);
      break;
    case "LGA":
      lgaPayload.value = new UpdateLGADTO(data);
      break;
    default:
      break;
  }
  selectedAddressType.value = type;
  return { type, data };
});

const countries = computed(() => {
  const _countries = store.state.addresses.countries;
  return _countries.map(
    (_country) => new SelectOption(_country.name, _country.id)
  );
});

const states = computed(() => {
  const _states = store.state.addresses.states;
  return _states.map((_state) => new SelectOption(_state.name, _state.id));
});

const selectAddressType = (address) => {
  selectedAddressType.value = address;
};

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const submitCountry = async () => {
  if (!propData.value) {
    const message = await store.dispatch(
      "addresses/createCountry",
      countryPayload.value
    );
    notificationService.success(message);
  } else {
    const message = await store.dispatch(
      "addresses/updateCountry",
      countryPayload.value
    );
    notificationService.success(message);
  }
  emit("close");
};

const submitState = async () => {
  if (!propData.value) {
    const message = await store.dispatch(
      "addresses/createState",
      statePayload.value
    );
    notificationService.success(message);
  } else {
    const message = await store.dispatch(
      "addresses/updateState",
      statePayload.value
    );
    notificationService.success(message);
  }
  emit("close");
};

const submitLGA = async () => {
  if (!propData.value) {
    const message = await store.dispatch(
      "addresses/createLGA",
      lgaPayload.value
    );
    notificationService.success(message);
  } else {
    const message = await store.dispatch(
      "addresses/updateLGA",
      lgaPayload.value
    );
    notificationService.success(message);
  }
  emit("close");
};

const deleteCountry = async () => {
  const message = await store.dispatch(
    "addresses/deleteCountry",
    countryPayload.value
  );
  notificationService.success(message);
  emit("close");
};

const deleteState = async () => {
  const message = await store.dispatch(
    "addresses/deleteState",
    statePayload.value
  );
  notificationService.success(message);
  emit("close");
};

const deleteLGA = async () => {
  const message = await store.dispatch("addresses/deleteLGA", lgaPayload.value);
  notificationService.success(message);
  emit("close");
};

// eslint-disable-next-line
defineExpose({
  states,
  countries,
  lgaPayload,
  statePayload,
  countryPayload,
  addressTypes,
  submitLGA,
  submitState,
  submitCountry,
  deleteLGA,
  deleteState,
  deleteCountry,
  selectAddressType,
});
</script>
