import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import metaWrapper from './meta-wrapper';
import authGuard from './auth-guard';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  authGuard(to, next)
  metaWrapper(to, from)
})

export default router
