export class CreateCategoryDTO {
  constructor(category) {
    this.name = category.name;
    this.type = category.type;
    this.frequency = category.frequency || null;
    this.channel = category.channel;
  }

  createEmpty() {
    return new CreateCategoryDTO({ name: '', type: '' });
  }
}

export class UpdateCategoryDTO extends CreateCategoryDTO {
  constructor(category) {
    super(category)
    this.id = category.id
  }
}