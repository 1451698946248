<template>
  <div class="">
    <div class="flex items-center justify-between">
      <h4>{{ book.name }}</h4>
      <h4>{{ book.formattedPrice }}</h4>
    </div>
    <div class="grid grid-cols-2 gap-6">
      <div class="w-full h-80 rounded overflow-hidden">
        <img
          class="w-full h-full object-cover"
          :src="book.image"
          :alt="`${book.author}'s ${book.name}`"
        />
      </div>
      <div class="flex flex-col justify-between">
        <div class="flex flex-col space-y-3">
          <div>
            <p class="text-sm">Quantity Sold</p>
            <h2>{{ book.quantitySold }}</h2>
          </div>
          <div>
            <p class="text-sm">Inventory</p>
            <h2>{{ book.quantity }}</h2>
          </div>
        </div>
        <ButtonComponent :fullWidth="true" @click="editBook()">
          Edit Book Details
        </ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookDetailModal",
};
</script>

<script setup>
import { inject, ref } from "@vue/runtime-core";
import Book from "@/models/book";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Book,
    required: false,
  },
});

const openModal = inject("openModal");
// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const book = ref(props.data);

const editBook = () => {
  emit("close");
  openModal("book", book, "large");
};

// eslint-disable-next-line
defineExpose({
  book,
  editBook,
});
</script>
