import { httpService } from "@/services/http.service"
import { Analytics } from "@/models/analytics"

const state = {
  analytics: Analytics.prototype.createEmpty()
}

const actions = {
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("staff/dashboard", { params: options })
    commit("SET_ANALYTICS", response.data.data)
  },
}

const mutations = {
  SET_ANALYTICS: (state, analytics) => {
    state.analytics = new Analytics(analytics)
  },
  CLEAR: (state) => {
    state.analytics = Analytics.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}