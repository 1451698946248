import store from "@/store";
import axios from "axios";
import authService from "./auth.service"
import notificationService from "./notification.service";
import router from "@/router";

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
}

let activeRequests = 0;

const httpService = axios.create(config);

const authInterceptor = (config) => {
  const token = authService.getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (activeRequests == 0) {
    store.dispatch('loading/startLoading');
  }

  activeRequests++

  return config
}

httpService.interceptors.request.use(authInterceptor)

httpService.interceptors.response.use(
  (response) => {
    activeRequests--
    if (activeRequests == 0) {
      store.dispatch('loading/stopLoading');
    }
    return response
  },
  (error) => {
    activeRequests--
    if (activeRequests == 0) {
      store.dispatch('loading/stopLoading');
    }
    const code = parseInt(error.response && error.response.status);
    const message = error.response.data?.error?.message || error.response.data?.message;

    if (code && code === 401) {
      store.dispatch('auth/logout')
      router.push({ name: 'login' })
    }

    if (code && code !== 404) {
      notificationService.error(message)
    }

    if (!code) {
      notificationService.error("Something went wrong. Please try again later.")
    }

    return Promise.reject(error)
  }
)

export { httpService }