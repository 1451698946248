import { User } from "./user";

export default class Subscriber extends User {
  constructor(subscriber) {
    super(subscriber)
    this.subscriptionStatus = subscriber.subscriptionStatus ? 'active' : 'inactive'
  }

  get dateJoined() {
    return new Date(this.createdAt).toDateString()
  }
}