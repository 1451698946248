import { httpService } from "@/services/http.service"
import Lesson from "@/models/lesson"

const actions = {
  create: async (context, data) => {
    const response = await httpService.post("lesson", data)
    return response.data;
  },
  getAllForChannel: async (context, options) => {
    const response = await httpService.get("lesson", { params: options })
    const lessons = response.data.data.result.map((lesson) => new Lesson(lesson))
    const pagination = { ...response.data.data.page, totalCount: response.data.data.totalCount }
    return { lessons, pagination }
  },
  get: async (context, data) => {
    const response = await httpService.get(`lesson/${data}`)
    return new Lesson(response.data.data)
  },
  update: async (context, data) => {
    const response = await httpService.patch(`lesson/${data.get('id')}`, data)
    return response.data.message
  },
  delete: async (context, data) => {
    const response = await httpService.delete(`lesson/${data}`)
    return response.data.message
  }
}

export default {
  namespaced: true,
  actions,
}