import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Book from "@/models/book"

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("book", data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("book", { params: options })
    commit("SET_MAGAZINES", { books: response.data.data, options })
  },
  get: async (context, data) => {
    const response = await httpService.get(`book/${data}`)
    return new Book(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`book/${data.get('id')}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`book/${data}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_MAGAZINES: (state, payload) => {
    const booksPagination = new Pagination(payload.books, "books/getAll", payload.options)
    booksPagination.result = booksPagination.result.map((_book) => new Book(_book))
    state.list = booksPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}