import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Staff from "@/models/staff"
import { StaffActivity } from "@/models/activity"

const state = {
  list: Pagination.prototype.createEmpty(),
  activities: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("staff", data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("staff", { params: options })
    commit("SET_STAFF", { staff: response.data.data, options })
  },
  get: async (context, data) => {
    const response = await httpService.get(`staff/${data}`)
    return new Staff(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`staff/${data.id}`, data)
    dispatch("getAll")
    return response.data.message
  },
  updateUser: async (context, data) => {
    const response = await httpService.patch(`staff/${data.get('id')}`, data)
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`staff/${data}`)
    dispatch("getAll")
    return response.data.message
  },
  getAllActivities: async ({ commit }, options) => {
    const response = await httpService.get("staff/activity", { params: options })
    commit("SET_ACTIVITIES", { ...response.data.data })
  },
}

const mutations = {
  SET_STAFF: (state, payload) => {
    const staffPagination = new Pagination(payload.staff, "staff/getAll", payload.options)
    staffPagination.result = staffPagination.result.map((_staff) => new Staff(_staff))
    state.list = staffPagination
  },
  SET_ACTIVITIES: (state, activities) => {
    const activitiesPagination = new Pagination(activities, "staff/getAllActivities")
    activitiesPagination.result = activitiesPagination.result.map((activity) => new StaffActivity(activity))
    state.activities = activitiesPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
    state.activities = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}