const state = {
  type: null,
  data: null,
  size: null,
  opened: false,
  closeFunction: null,
}

const actions = {
  open: async ({ commit }, config) => {
    if (config) {
      commit('SET_TYPE', config.type)
      commit('SET_DATA', config.data)
      commit('SET_SIZE', config.size || 'small')
      commit('SET_CLOSE_FUNCTION', config.closeFunction || null)
      commit('SET_STATUS', true)
    }
  },

  close: async ({ commit }) => {
    commit('SET_STATUS', false)
    commit('SET_DATA', null)
    commit('SET_TYPE', null)
    commit('SET_CLOSE_FUNCTION', null)
  }
}

const mutations = {
  SET_STATUS: (state, openState) => {
    state.opened = openState
  },
  SET_TYPE: (state, type) => {
    state.type = type
  },
  SET_DATA: (state, data) => {
    state.data = data
  },
  SET_SIZE: (state, size) => {
    state.size = size
  },
  SET_CLOSE_FUNCTION: (state, closeFunction) => {
    state.closeFunction = closeFunction
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}