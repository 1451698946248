import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Release from "@/models/release"

const state = {
  list: Pagination.prototype.createEmpty(),
}

const actions = {
  getForMagazine: async ({ commit }, options) => {
    const response = await httpService.get("magazine/release", { params: options })
    commit("SET_RELEASES", { releases: response.data.data, options })
  },
  create: async (context, data) => {
    const response = await httpService.post("magazine/release", data)
    return response.data;
  },
  get: async (context, data) => {
    const response = await httpService.get(`magazine/release/${data}`)
    return new Release(response.data.data)
  },
  update: async (context, data) => {
    const response = await httpService.patch(`magazine/release/${data.get('id')}`, data)
    return response.data.message
  },
  delete: async (context, data) => {
    const response = await httpService.delete(`magazine/release/${data}`)
    return response.data.message
  }
}

const mutations = {
  SET_RELEASES: (state, payload) => {
    const releasePagination = new Pagination(payload.releases, "releases/getForMagazine", payload.options)
    releasePagination.result = releasePagination.result.map((_release) => new Release(_release))
    state.list = releasePagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}