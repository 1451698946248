import Channel from "./channel";

export default class Category {
  constructor(category) {
    this.id = category._id
    this.name = category.name;
    this.type = category.type;
    this.frequency = category.frequency || null;
    this.channel = new Channel(category.channel);
    this.createdAt = category.createdAt;
    this.updatedAt = category.updatedAt;
  }
}

export const CategoryType = Object.freeze({
  timeBased: 'time-based',
  selfPaced: 'self-paced',
})

export const CategoryFrequency = Object.freeze({
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
})