export class CreateLessonDTO {
  constructor(lesson) {
    this.title = lesson.title;
    this.content = lesson.content;
    this.publishDate = lesson.publishDate;
    this.category = lesson.category;
  }

  createEmpty() {
    return new CreateLessonDTO({ title: '', content: '' });
  }
}

export class UpdateLessonDTO extends CreateLessonDTO {
  constructor(lesson) {
    super(lesson)
    this.id = lesson.id
    this.image = lesson.image
    this.publishDate = lesson.publishDate
    this.isPublished = lesson.isPublished
  }
}