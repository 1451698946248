import formatAndCapitalize from "@/providers/formatAndCapitalize";
import { User } from "./user";

export default class Staff extends User {
  constructor(staff) {
    super(staff)
  }

  get channels() {
    return this.info.channels
  }
  

  get capitalizedRole() {
    return formatAndCapitalize(this.role)
  }
}