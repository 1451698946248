<template>
  <div class="option">
    <p class="small text-white whitespace-nowrap">{{ option.value }}</p>
    <IconComponent
      @click="$emit('remove', option)"
      icon="bi:x"
      color="white"
      class="cursor-pointer stroke-white"
    />
  </div>
</template>

<script>
  export default {
    name: 'OptionPill',
  }
</script>

<script setup>
import SelectOption from "@/models/select-option";
// eslint-disable-next-line
const props = defineProps({
  option: {
    type: SelectOption,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
.option {
  @apply flex items-center space-x-2 px-5 py-[10px] rounded-[10px] bg-primary-200;
}
</style>