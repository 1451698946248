import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Category from "@/models/category"

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async (context, data) => {
    const response = await httpService.post("category", data)
    return response.data.message;
  },
  // getAll: async ({ commit }, options) => {
  //   const response = await httpService.get("category", { params: options })
  //   commit("SET_CATEGORIES", response.data.data)
  // },
  getAllForChannel: async (context, options) => {
    const response = await httpService.get("category", { params: options })
    return response.data.data.result.map((category) => new Category(category))
  },
  get: async (context, data) => {
    const response = await httpService.get(`category/${data}`)
    return new Category(response.data.data)
  },
  update: async (context, data) => {
    const response = await httpService.patch(`category/${data.id}`, data)
    return response.data.message
  },
  delete: async (context, data) => {
    const response = await httpService.delete(`category/${data}`)
    return response.data.message
  }
}

const mutations = {
  SET_CHANNELS: (state, categories) => {
    const categoriesPagination = new Pagination(categories, "categories/getAll")
    categoriesPagination.result = categoriesPagination.result.map((_category) => new Category(_category))
    state.list = categoriesPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}