<template>
  <button
    class="rounded-lg font-semibold font-serif select-none"
    :class="[
      small ? 'py-2 px-6 text-sm' : 'text-lg px-4 py-3 md:text-xl md:px-8',
      fullWidth ? 'w-full' : '',
      loader && loading ? 'bg-gray-200' : type,
      disabled ? 'disabled' : '',
    ]"
    :disabled="disabled"
    @click="$emit('click.stop')"
    :type="ordinary ? 'button' : 'submit'"
  >
    <span class="flex items-center text-white justify-center gap-x-1 truncate">
      <IconComponent v-if="variation == 'add'" icon="akar-icons:plus" />
      <LoadingComponent width="30px" v-if="loader" />
      <slot v-if="!loading || !loader" />
    </span>
  </button>
</template>

<script>
  export default {
    name: 'ButtonComponent',
  }
</script>

<script setup>
const { computed } = require("@vue/runtime-core");
const { useStore } = require("vuex");

// eslint-disable-next-line
const props = defineProps({
  type: {
    type: String,
    default: "primary",
  },
  loader: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  ordinary: {
    type: Boolean,
    default: false,
  },
  variation: {
    type: String,
  },
});

const store = useStore();

const loading = computed(() => {
  return store.state.loading.loading;
});
</script>

<style scoped>
.error {
  @apply bg-system-red;
}

.primary {
  @apply bg-primary-500;
}

.success {
  @apply bg-system-green;
}

.warning {
  @apply bg-system-orange;
}

.disabled {
  @apply bg-gray-500 text-gray-300 cursor-not-allowed;
}

.dark {
  @apply bg-gray-700;
}
</style>