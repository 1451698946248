import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import Magazine from "@/models/magazine"

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("magazine", data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get("magazine", { params: options })
    commit("SET_MAGAZINES", response.data.data)
  },
  getEvery: async () => {
    const response = await httpService.get("magazine", { params: { limit: 100 } })
    return response.data.data.result.map((magazine) => new Magazine(magazine))
  },
  get: async (context, data) => {
    const response = await httpService.get(`magazine/${data}`)
    return new Magazine(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`magazine/${data.get('id')}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`magazine/${data}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_MAGAZINES: (state, magazines) => {
    const magazinesPagination = new Pagination(magazines, "magazines/getAll")
    magazinesPagination.result = magazinesPagination.result.map((_magazine) => new Magazine(_magazine))
    state.list = magazinesPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}