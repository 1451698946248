export class CreateChannelDTO {
  constructor(channel) {
    this.name = channel.name;
    this.description = channel.description;
  }

  createEmpty() {
    return new CreateChannelDTO({ name: '', description: '' });
  }
}

export class UpdateChannelDTO extends CreateChannelDTO {
  constructor(channel) {
    super(channel)
    this.id = channel.id
    this.image = channel.image
  }
}